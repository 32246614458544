.FullVersionLock {
    position: relative;

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
    }

    &-bluredContent {
        filter: blur(10px);

        &.animated {
            -webkit-animation: blur_process 2s; 
        }
    }

    &-visibleContent {
        position: relative;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);

        width: 558px;
        height: 265px;

        align-items: center;

        background: var(--klaim-color-white);
        border-radius: 10px;
        box-shadow: 0 5px 14px var(--klaim-color-blue-gray2);
        -webkit-box-shadow: 0 5px 14px var(--klaim-color-blue-gray2);

        padding: 48px 0;

        &.animated {
            -webkit-animation: fadeIn 2s;
            animation: fadeIn 2s;
        }
    }

    @-webkit-keyframes blur_process {
        0% { -webkit-filter: blur(0px);}
        50% { -webkit-filter: blur(5px);}
        100% { -webkit-filter: blur(10px);}
    }

    @-webkit-keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
