.Confirm {
    &-icon {
        color: var(--klaim-color-yellow);
        margin-right: 16px;
        font-size: 22px;
    }

    &-dialog {
        & .ant-modal-title {
            display: flex;
        }

        & .ant-modal-body {
            padding: 8px 32px 0 70px;
        }

        & .ant-modal-footer {
            border-top: none;
            padding: 24px 32px;
        }

        & .ant-modal-header {
            border-bottom: none;
            padding: 32px 32px 0 32px;
        }
    }
}