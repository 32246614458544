.PrivateLayout {
    display: flex;
    justify-content: center;
    background: var(--klaim-color-gray1);
    min-width: 1280px;

    &-container {
        min-height: 100vh;
        max-width: 1920px;
    }

    &-header {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 4px var(--klaim-color-shadow);

        padding: 0 24px 0 20px;
        margin: 0;
    }

    @media (--large-viewport) {
        &-header {
            border-radius: 0 0 10px 10px;
        }
    }

    &-content {
        background: inherit;
    }

    &-menuSection {
        display: flex;
        align-items: center;
        font-size: 18px;
        flex: 1;
    }

    &-logo {
        height: 42px;
        margin-left: 8px;
    }

    &-logo,
    &-menuButton {
        margin-right: 28px;
    }
}

.mobile {
    &.PrivateLayout {
        min-width: 100vw;
    }
}