.CredentialsForm {
    &-form {
        padding: 26px 24px;
        width: 600px;
    }

    &-buttonsSection {
        display: flex;
        justify-content: flex-end;
    }
}