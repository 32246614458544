.PatientInfo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 20px 70px;

    &-patientName {
        font-size: 18px;
        color: var(--klaim-color-dark-blue);

    }

    &-patientInto {
        color: var(--klaim-color-dark-blue3);
    }

    &-birthDate {
        color: var(--klaim-color-dark-blue2);
    }

    &-label {
        color: var(--klaim-color-dark-blue3);
        letter-spacing: 1.5px;
        font-size: 10px;
        font-weight: var(--klaim-font-weight-medium);
    }

    &-value {
        color: var(--klaim-color-dark-blue3);
    }

    &-firstSection {
        display: grid;
        grid-template-columns: auto 1fr 1fr;
        grid-gap: 28px;
    }

    &-secondSection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 28px;
    }

    &-infoSection {
        line-height: 28px;
    }
}
