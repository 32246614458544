.BulkUpload {
    &-icon {
        width: 32px;
        filter: var(--klaim-filter-color-primary);
        margin-bottom: 16px;
    }

    &-dragger {
        padding: 0 134px;

        &.ant-upload.ant-upload-drag {
            height: 165px;
            background: var(--klaim-color-white);
        }
    }

    &-noteText {
        font-size: 12px;
        color: var(--klaim-color-dark-blue3);
        text-align: left;
    }

    &-downloadTemplate {
        padding: 0;
        margin-top: 8px;
    }

    &-footer {
        margin-top: 24px;
        padding: 0 12px;
    }
}