.UserInfo {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-avatar {
        margin-left: 16px;
    }

    &-name {
        font-size: 16px;
        color: var(--klaim-color-dark-blue);
        margin-top: 18px;
    }

    &-email {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        font-size: 12px;
        color: var(--klaim-color-dark-blue3) margin-top 4px;
    }

    &-popoverOverlay {
        width: 362px;

        & .ant-popover-inner-content {
            padding: 42px 12px 14px;
        }
    }

    &-menu.ant-menu-vertical {
        border-right: none;

        & > .ant-menu-item {
            height: auto;
            line-height: 32px;
            padding: 5px 12px;
            margin: 0;
            font-weight: var(--klaim-font-weight-medium);
        }

        & > .ant-menu-item-divider {
            margin: 15px 0;
        }
    }

    &-popoverHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 7px;
    }
}