.el-bu-ErrorModal {
    & .ant-modal-confirm-btns {
        display: none;
    }

    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
        margin-left: 0;
    }

    &-buttons {
        justify-content: flex-end;
        width: 100%;
        padding-top: 20px;
    }
}