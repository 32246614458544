.FileList {
    &-moreActions .ant-popover-inner-content {
        padding: 0;
    }

    &-menu.ant-menu-vertical {
        border-right: none;

        & > .ant-menu-item {
            height: auto;
            line-height: 22px;
            padding: 5px 12px;
            margin: 0;
        }
    }
}