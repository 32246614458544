.Gatekeeper {
    &-title {
        font-size: 10px;
        letter-spacing: 1.5px;
        color: var(--klaim-color-dark-blue3);
        font-weight: var(--klaim-font-weight-medium);
    }

    &-value {
        list-style-position: inside;
    }
}