.ClientList {
    flex: 1;
    background: var(--klaim-color-white);
    box-shadow: 0 1px 2px var(--klaim-color-shadow);
    border-radius: 10px;

    &-search {
        width: 400px;
    }

    &-actionPanel {
        padding: 21px 24px;
        display: flex;
        justify-content: space-between;
    }
}