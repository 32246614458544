.EligibilityRequest {
    &-form {
        width: 600px;
        display: flex;
        flex-direction: column;
        gap: 26px;
    }

    &-buttonsSection {
        display: flex;
        justify-content: flex-end;
    }

    &-detailedForm {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 24px;
    }

    &-documentIcon,
    &-patientIcon {
        filter: var(--klaim-filter-color-dark-blue);
        margin-right: 8px;
        height: 26px;
    }

    &-cardTitle {
        display: flex;
        align-items: center;
        color: var(--klaim-color-dark-blue);
    }

    &-portalFooterDivider {
        margin: 8px 0;
    }

    &-portalFooterSpace {
        padding: 0 8px 4px;
        width: 100%;
        justify-content: flex-end;
    }
}
