@import 'custom-test-antd/dist/index.css';
@import 'variables.pcss';

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: var(--klaim-font-family) !important;
}

#root {
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--klaim-font-family) !important;
}