.EligibilityBulk {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-upload {

    }

    &-fileList {
        margin-top: 20px;
        width: 100%;
    }
}