.Menu {
    border-radius: 10px;

    &.ant-menu-vertical {
        border-right-color: transparent;

        & > .ant-menu-item {
            height: 47px;
            line-height: 47px;
        }
    }
}

.MenuLabel {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 14px;
    height: 100%;

    &-titleSection {
        line-height: 20px;
        font-weight: var(--klaim-font-weight-medium);
    }

    &-label {
        font-size: 16px;
    }

    &-description {
        color: var(--klaim-color-dark-blue3);
        font-size: 10px;
    }
}

.MenuTextLabel {
    font-size: 16px;
    font-weight: var(--klaim-font-weight-medium);
}
