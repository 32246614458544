.Note {
    &-title {
        font-size: 10px;
        letter-spacing: 1.5px;
        color: var(--klaim-color-dark-blue3);
        font-weight: var(--klaim-font-weight-medium);
    }

    &-value {
        color: var(--klaim-color-dark-blue2);
    }

    &-consultation {
        padding-top: 10px;
    }
}