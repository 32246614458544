.FinishRegistration {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    &.mobile {
        max-width: 100vw;
        padding-top: 0;
    }
}
