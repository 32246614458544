.EligibilityList {
    flex: 1;

    &-table.ant-table-wrapper {
        & .ant-table-tbody > tr > td {
            padding: 12px 16px;
        }

        .ant-pagination-total-text {
            flex: 1;
            margin-left: 20px;
        }
    }

    &-actionPanel {
        padding: 21px 24px;
        display: flex;
        justify-content: space-between;
    }

    &-tableRow {
        cursor: pointer;
        vertical-align: text-top;
    }

    &-patientName {
        font-weight: var(--klaim-font-weight-medium);
        white-space: pre-wrap;
        padding: 0;
        color: var(--klaim-color-dark-blue);

        &:hover {
            color: var(--klaim-color-primary)
        }
    }

    &-firstLine {
        white-space: pre-wrap;
    }

    &-subLine {
        font-size: 12px;
        color: gray;
    }

    &-subLineLabel {
        font-weight: var(--klaim-font-weight-medium);
    }

    &-pendingTag {
        color: var(--klaim-color-blue2);
        background: var(--klaim-color-blue2-light);
    }
}