.Insurance {
    &-title {
        font-size: 20px;
        font-weight: var(--klaim-font-weight-medium);
        margin-bottom: 25px;
    }

    &-infoSection {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 25px 50px;
        line-height: 14px;
    }

    &-download {
        display: flex;
        align-items: center;
    }

    &-downloadIcon {
        margin-right: 10px;
    }

    &-label {
        color: var(--klaim-color-dark-blue3);
        font-size: 10px;
        font-weight: var(--klaim-font-weight-medium);
        letter-spacing: 1.5px;

        &:after {
            content: ": "
        }
    }

    &-value {
        color: var(--klaim-color-dark-blue);
    }

    &-statusSection {
        display: flex;
        align-items: center;
    }

    &-statusValue {
        margin-right: 6px;
    }

    &-status {
        height: 14px;
        width: 14px;
        border-radius: 8px;
        margin-right: 12px;
        display: inline-block;

        &.eligible {
            background: var(--klaim-color-green);
        }

        &.notEligible {
            background: var(--klaim-color-red);
        }
    }
}