.AuthCard {
    display: flex;
    flex-direction: column;
    padding: 44px;
    background-color: var(--klaim-color-white);
    box-shadow: 0 1px 2px var(--klaim-color-shadow);
    border-radius: 10px;
    width: 428px;

    &.mobile {
        width: 100%;
    }

    &-header {
        color: var(--klaim-color-dark-blue);
        font-size: 24px;
        margin-bottom: 8px;
    }

    &-headerText {
        color: var(--klaim-color-dark-blue3);
        margin-bottom: 24px;
        font-size: 12px;
        line-height: 16px;
    }
}