.EligibilityResponse {
    &-tabs,
    &-spin {
        width: 100%;
    }

    &-info {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(610px, 1fr) );
        grid-gap: 22px;
        width: 100%;
        grid-auto-flow: row dense;
    }

    &-card {
        width: 100%;
        padding: 24px;

        &.patient {
            grid-column: 1 / -1;
        }
    }

    &-leftColumn,
    &-rightColumn {
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    &-upgradeFullVersionOverlay {
        min-height: 300px;
        width: 100%;
    }
}