.EligibilityListFilter {
    &-label {

    }

    &-popupContent {
        display: flex;
        flex-direction: column;
        max-width: 320px;
    }

    &-filterItem:not(:last-child) {
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
    }

    &-buttonSection {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    &-search {
        width: 400px;
    }

    &-filterButton {
        margin-left: 16px;
    }
}