.EligibilityResponsePopup {
    &-tabs,
    &-spin {
        width: 100%;
    }

    &-label {
        color: var(--klaim-color-dark-blue3);
        font-size: 10px;
        font-weight: var(--klaim-font-weight-medium);
        letter-spacing: 1.5px;

        &:after {
            content: ": "
        }
    }

    &-value {
        color: var(--klaim-color-dark-blue);
    }

    &-note {
        margin-top: 10px;
    }

    &-overlay {
        width: 500px;
    }
}