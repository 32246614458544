.Dashboard {
    &-content {
        width: 100%;
    }

    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-branchSelect {
        width: 250px;
    }

    &-datesSelect {
        width: 250px;
    }
}