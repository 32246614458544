.MenuButton {
    cursor: pointer;

    &-header {
        color: var(--klaim-color-primary);
        padding: 0 16px;
        font-size: 16px;
        margin-bottom: 8px;
    }

    &-overlay .ant-popover-inner-content {
        padding: 22px 10px;
        width: 360px;
    }

    &-discoverMenu,
    &-modulesMenu {
        margin-bottom: 18px;
    }

    &-toolMenu {
        &.ant-menu-vertical {
            border-right-color: transparent;

            & > .ant-menu-item {
                height: 34px;
                line-height: 34px;
            }
        }
    }
}