.MenuContentLayout {
    padding: 0 24px 28px;
    background: inherit;

    &-menuSection {
        display: flex;
        justify-content: center;
    }

    &-menu {
        border-radius: 0 0 10px 10px;
        box-shadow: 0 1px 2px var(--klaim-color-shadow);
        justify-content: center;
    }

    &-content {
        margin-top: 28px;
        height: auto;
        min-height: 100%;
        display: flex;
        justify-content: center;
    }
}

.mobile {
    &.MenuContentLayout,
    & .MenuContentLayout {
        padding: 0 0 28px;

        &-menuSection {
            padding: 0 24px;
        }
    }
}