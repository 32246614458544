.UpgradeFullVersionWarning {
    &-title {
        text-align: center;
        letter-spacing: 0.15px;
        color: var(--klaim-color-primary);
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 24px;
    }

    &-description {
        text-align: center;
        letter-spacing: 0.4px;
        color: var(--klaim-color-blue-gray);
        font-size: 12px;
        line-height: 16px;
        padding: 0 90px;
    }

    &-callback {
        margin-top: 32px;
    }
}