.CredentialsList {
    flex: 1;

    &-tableRow {
        cursor: pointer;
    }

    &-validIcon {
        filter: var(--klaim-filter-color-green)
    }

    &-invalidIcon {
        filter: var(--klaim-filter-color-red)
    }

    &-moreActions .ant-popover-inner-content {
        padding: 0;
    }

    &-menu.ant-menu-vertical {
        border-right: none;

        & > .ant-menu-item {
            height: auto;
            line-height: 22px;
            padding: 5px 12px;
            margin: 0;
        }
    }

    &-actionPanel {
        padding: 21px 24px;
        display: flex;
        justify-content: space-between;
    }

    &-search {
        width: 400px;
    }
}