.EligibilityBulkRequest {
    &-link {
        color: var(--klaim-color-primary);
    }

    &-errorDetails {
        text-align: right;
    }

    &-timeSelector {
        width: 80px;
    }

    &-header {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
    }
}